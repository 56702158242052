import { memo, useCallback } from "react";

interface FieldProps {
  onFieldChange: (name: string,value: React.MouseEvent<HTMLElement>) => void;
  fieldType: string;
  fieldName: string;
  fieldValue: string;
  hasError: boolean;
  labelText: string;
}

export const Field = memo((props: FieldProps) => {
  const onFieldChange = useCallback(
    (event: { target: { value: any } }) => {
      props.onFieldChange(props?.fieldName, event.target.value);
    },
    [props]
  );

  return (
    <fieldset>
      <label
        htmlFor={props?.fieldName}
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      >
        {props?.labelText}
      </label>

      <input
        className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        type={props?.fieldType}
        name={props?.fieldName}
        id={props?.fieldName}
        onChange={onFieldChange}
        value={props?.fieldValue}
      />

      {props?.hasError && (
        <p className="text-xs text-red-400 italic">{`This field is required.`}</p>
      )}
    </fieldset>
  );
});
