import React from "react";
import { footerData, footerLabelData } from "../../pages/api/footerData";
import Heading3 from "./Wrappers/Heading3";
import Section from "./Wrappers/Section";
import BonatraLogoFull from "../../public/logoFull.png";
import Image from "next/image";
import Paragraph from "./Wrappers/Paragraph";
import { MdMail, MdSmartphone, MdLocationOn } from "react-icons/md";
import { Button } from "@chakra-ui/react";
import { useRouter } from "next/router";
const Footer = ({ footerData }: { footerData: footerData }) => {
  return (
    <footer className="bg-black py-12 rounded-t-[4rem]">
      <Section className="flex flex-col md:flex-row justify-between gap-8">
        <div className="flex flex-col gap-8">
          <Image src={BonatraLogoFull} alt="logo" />
          <FooterSocialLogos />
          <ReachUsOn />
          <CompanyNumbers />
        </div>
        <ul className="md:grid grid-cols-3 gap-8 sm:flex sm:flex-wrap ">
          {footerData?.map((el, idx) => (
            <FooterSubComponent key={idx} footerLabelData={el} />
          ))}
        </ul>
      </Section>
    </footer>
  );
};

export default Footer;

const FooterSubComponent = ({
  footerLabelData,
}: {
  footerLabelData: footerLabelData;
}) => {
  const router = useRouter();

  const handleRoutes = (index: number) => {
    router.push(
      {
        pathname: `${footerLabelData?.route[index]}`,
      },
      undefined,
      { shallow: true }
    );
  };

  return (
    <li className="mb-4">
      <p className="text-primary font-semibold mb-4 text-2xl font-semibold">
        {footerLabelData.title}
      </p>
      <ul className="flex flex-col justify-start items-start content-start">
        {footerLabelData.labels.map((el, index) => {
          return (
            <Button
              variant="unstyled"
              key={index}
              onClick={() => {
                handleRoutes(index);
              }}
            >
              <li key={el} className="text-white cursor-pointer ">
                {el}
              </li>
            </Button>
          );
        })}
      </ul>
    </li>
  );
};

const FooterSocialLogos = () => {
  return (
    <>
      <ul className="flex gap-4 mt-8">
        {footerSocialLogoSrc.map((logo) => (
          <li key={logo.icon}>
            <a href={logo.href} title={logo.name} target="_blank">
              <Image
                src={logo.icon}
                alt={logo.name}
                height={40}
                width={40}
              ></Image>
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

const footerSocialLogoSrc = [
  // { href: "", name: "whatsapp", icon: "https://assets.bonatra.com/package_detail/Whatsappicon.webp" },
  {
    href: "https://www.facebook.com/bonatra.health",
    name: "facebook",
    icon: "https://assets.bonatra.com/package_detail/facebook-1.webp",
  },
  {
    href: "https://instagram.com/bonatra.health?igshid=YmMyMTA2M2Y=",
    name: "instagram",
    icon: "https://assets.bonatra.com/package_detail/instagram-1.webp",
  },
  {
    href: "https://www.linkedin.com/company/76988968/admin/",
    name: "linkedin",
    icon: "https://assets.bonatra.com/package_detail/Linked%20in.webp",
  },
  {
    href: "https://play.google.com/store/apps/details?id=com.yoihealth",
    name: "playstore icon",
    icon: "https://assets.bonatra.com/icons/google-play.webp",
  },
  {
    href: "https://apps.apple.com/in/app/bonatra/id1600079061",
    name: "appstore icon",
    icon: "https://assets.bonatra.com/icons/app-store.webp",
  },
];

const ReachUsOn = () => {
  return (
    <article className="flex flex-col gap-2">
      <Heading3 classname="text-primary">Contact us:</Heading3>
      <Paragraph className="text-white flex gap-2 items-start">
        <MdLocationOn size={25} color="#fff" />
        1848/C, 14th Cross, 21st Main Rd, Vanganahalli,
        <br /> 1st Sector, HSR Layout,
        <br /> Bengaluru, Karnataka 560102
      </Paragraph>
      <Paragraph className="text-white flex gap-2 items-center">
        <MdSmartphone size={20} color="#fff" />
        +91 8095023777
      </Paragraph>
      <Paragraph className="text-white flex gap-2 items-center">
        <MdMail size={20} color="#fff" />
        livelonger@bonatra.com
      </Paragraph>
    </article>
  );
};
const CompanyNumbers = () => {
  return (
    <article className="mb-8 md:mb-0">
      <Paragraph className="text-white">CIN: U85300KA2021PTC153931</Paragraph>
      <Paragraph className="text-white">GSTIN: 29AAKCB2775Q1ZS</Paragraph>
    </article>
  );
};
