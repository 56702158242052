import { FormEventHandler, memo } from "react";
import { Checkbox, Link } from "@chakra-ui/react";
import { Field } from "./Field";
import ScriptLoader from "./ScriptLoader";

type Values = {
  firstName: string;
  lastName: string;
  email_id: string;
  mobile_no: string;
};

type Errors = {
  firstName: boolean,
  lastName: boolean,
  mobile_no: boolean,
}

interface FormProps {
  values: Values;
  errors: Errors;
  onSubmit?: FormEventHandler<HTMLFormElement> | undefined;
  fieldValue?: number | string;
  onFieldChange: (fieldName: any, value: any) => void;
  isChecked: boolean;
  setIsChecked: (value: boolean) => void;
  formSubmitted: boolean
}

export const Form = memo((props: FormProps) => (
  <div className="flex justify-center items-center">
    <form onSubmit={props.onSubmit} noValidate className="w-full max-w-lg" id="leads_form">
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <Field
            labelText="First name"
            fieldType="text"
            fieldName="firstName"
            fieldValue={props.values.firstName}
            hasError={props.errors.firstName}
            onFieldChange={props.onFieldChange}
          />
        </div>

        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <Field
            labelText="Last name"
            fieldType="text"
            fieldName="lastName"
            fieldValue={props.values.lastName}
            hasError={props.errors.lastName}
            onFieldChange={props.onFieldChange}
          />
        </div>
      </div>

      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <Field
            labelText="Email"
            fieldType="email"
            fieldName="email_id"
            fieldValue={props.values.email_id}
            hasError={false}
            onFieldChange={props.onFieldChange}
          />
        </div>

        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <Field
            labelText="Mobile Number"
            fieldType="mobile_no"
            fieldName="mobile_no"
            fieldValue={props.values.mobile_no}
            hasError={props.errors.mobile_no}
            onFieldChange={props.onFieldChange}
          />
        </div>
      </div>

      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          {/* <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Message
          </label>
          <textarea
            className="appearance-none block w-full bg-white h-40 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-password"
            placeholder="Message"
          /> */}
          <div className="text-gray-600 text-sm font-semibold">
            <Checkbox
              isChecked={props.isChecked}
              onChange={(event) => props.setIsChecked(event.target.checked)}
            />{" "}
            By submitting you are accepting
            <Link href="/tnc" target="_blank">
              <span className="text-primary underline">
                {" "}
                our terms and conditions
              </span>
            </Link>
          </div>
        </div>
      </div>

      <button
        type="submit"
        className="text-white w-full h-10 rounded-md bg-black disabled:bg-gray-200"
        disabled={!props.isChecked}
        onClick={() => {
         
        }}
      >
        Submit
      </button>
      {props?.formSubmitted && <ScriptLoader/>}
    </form>
  </div>
));
