//import Heading2 from '@/lib/Layout/Wrappers/Heading2';
import Section from '@/lib/Layout/Wrappers/Section';
import axios from 'axios';
import { memo, useCallback, useState,useEffect } from 'react'
import * as yup from 'yup'
import { Form } from "./Form";
import { useToast } from "@chakra-ui/react";
import { useRouter } from 'next/dist/client/router';
import {ga} from "react-ga"


const formSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  mobile_no: yup.number().required("This field is requried")
})

const initialFormValues = {
  firstName: '',
  lastName: '',
  email_id: '',
  mobile_no: '',
}

const initialErrorValues = {
  firstName: false,
  lastName: false,
  mobile_no: false,
}

export const LeadsForm = memo(() => {
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState(initialErrorValues)
  const [isChecked, setIsChecked] = useState(true);
  const [formSubmitted,setFormSubmitted] = useState(false);
  const toast = useToast();
  const navigation = useRouter()
  const router = useRouter();
  const isContactPage = navigation.pathname === "/contact"
  const isRingPage = navigation.pathname === "/bonatra-ring-x1"
  const isHomePage = navigation.pathname === "/"
  const onFieldChange = useCallback((fieldName: any, value: any) => {
    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  }, [])




  const onSubmit = useCallback(
    async (event: { preventDefault: () => void; }) => {
      event.preventDefault()
      const isFormValid = await formSchema.isValid(values, {
        abortEarly: false,
      })
      setFormSubmitted(false);
      const urlParams = new URLSearchParams(window.location.search);
      const utmParams = {
        utmSource: urlParams.get('utm_source'),
        utmMedium: urlParams.get('utm_medium'),
        utmCampaign: urlParams.get('utm_campaign'),
        utmTerm: urlParams.get('utm_term'),
        utmContent: urlParams.get('utm_content'),
      };


      if (isFormValid) {
        if(!values?.email_id){
          values["email_id"] = "dummy@test.com";
        }
        let newValues = { ...values, full_name: values.firstName + values.lastName,utmSource: utmParams.utmSource,utmMedium: utmParams.utmMedium,utmCampaign: utmParams.utmCampaign,utmTerm: utmParams.utmTerm,utmContent: utmParams.utmContent}
        setErrors(initialErrorValues);
        try {
          const { data: response } = await axios({
            method: 'post',
            url: `${process.env.NEXT_PUBLIC_API_URL}/api/method/bonatra.templates.includes.consultationform.create_lead`,
            data: isHomePage ? {
              type: 'Home Page',
              ...newValues,
            } : newValues
          })
          toast({
            title: "Thank you for your interest. Our expert will contact you soon",
            status: "success",
            duration: 2000,
            position: "top",
            isClosable: true,
          });
          setFormSubmitted(true);
          (window as any). fbq('trackCustom', 'Lead Form Submit');       
          setValues(initialFormValues);
        }
        catch (error) {
        }

      } else {
        formSchema.validate(values, { abortEarly: false }).catch((err) => {
          const errors = err.inner.reduce((acc: any, error: { path: any; }) => {
            return {
              ...acc,
              [error.path]: true,
            }
          }, {})

          setErrors((prevErrors) => {
            const newErrors = { ...prevErrors, ...errors };
            return newErrors;
          });
        })
      }
    },
    [values]
  )

  return (
    <Section className='pb-20'>
      <div className="flex flex-row justify-center items-center pb-8">
        <div className="h-0.5 w-16 bg-rose-600 m-3 rounded-md mb-2"></div>
        <div>Get in touch</div>
        <div className="h-0.5 w-16 bg-rose-600 m-3 rounded-md mb-2"></div>
      </div>
      {!isContactPage &&
        (
          !isRingPage && 
          <h6 className="text-center p-10 pt-0 text-4xl font-semibold">
            Get a free doctor consultation
          </h6>
        )
      }
      <Form
        values={values}
        errors={errors}
        onFieldChange={onFieldChange}
        onSubmit={onSubmit}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        formSubmitted={formSubmitted}
      />
      
    </Section>
  )
})



