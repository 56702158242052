import { useEffect } from 'react';

const ScriptLoader = () => {
  useEffect(() => {
    const loadScript = () => {
      if (window.location.pathname == '/') {
        var x = 0;
        var timer = setInterval(function () {          
            if (x == 0) {
              gtag('event', 'Submit', { 'event_category': 'Form', 'event_label': 'Leads Form' });
              x = 1;
            }
            clearInterval(timer);
        })
      }
    };
    loadScript();
  }, []);

  return null;
};

export default ScriptLoader;