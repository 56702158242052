import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";

type MetaProps = {
  title?: string;
  description?: string;
  keywords?: string;
  imgUrl?: string;
};

const Meta: React.FC<MetaProps> = ({ title, description, keywords, imgUrl }) => {
  const pathname = useRouter();
  const defaultImg = "https://assets.bonatra.com/images/bonatra-raush-logo.webp"

  return (
    <>
      <Head>

        {/* HTML Meta Tags */}
        <title>Best Holistic Health Care Company | Bonatra</title>
        <meta  name="description" content="Bonatra is the leading Holistic Health Care Company offering natural therapies to promote health and well-being. Improve your physical & mental health with Bonatra" />

          {/* Facebook Meta Tags */}
          <meta property="og:url" content="https://www.bonatra.com/" />
            <meta property="og:type" content="website" />
              <meta property="og:title" content="Best Holistic Health Care Company | Bonatra" />
                <meta property="og:description" content="Bonatra is the leading Holistic Health Care Company offering natural therapies to promote health and well-being. Improve your physical & mental health with Bonatra" />
                  <meta property="og:image" content={(imgUrl === "" || imgUrl === undefined) ? defaultImg : imgUrl} />

                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                      <meta property="twitter:domain" content="bonatra.com" />
                        <meta property="twitter:url" content="https://www.bonatra.com/" />
                          <meta name="twitter:title" content="Best Holistic Health Care Company | Bonatra" />
                            <meta name="twitter:description" content="Bonatra is the leading Holistic Health Care Company offering natural therapies to promote health and well-being. Improve your physical & mental health with Bonatra" />
                              <meta name="twitter:image" content={(imgUrl === "" || imgUrl === undefined) ? defaultImg : imgUrl} />

                                {/* Meta Tags Generated via https://www.opengraph.xyz */}

                                {title && <title>{title}</title>}
                                {description && <meta name="description" content={description} />}
                                {keywords && <meta name="keywords" content={keywords} />}
                                <link
                                  rel="canonical"
                                  href={`https://www.bonatra.com${pathname.asPath}`}
                                />
                              </Head>
                              <Script
                                src={`https://www.googletagmanager.com/gtag/js?id=G-EHN6KQZDD2`}
                                strategy="lazyOnload"
                              />
                              <Script strategy="lazyOnload" id="gtag-script-analytics">
                                {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-EHN6KQZDD2');
        `}
                              </Script>
                              <Script
                                async
                                src="https://www.googletagmanager.com/gtag/js?id=AW-10997782367"
                              />
                              <Script strategy="afterInteractive" id="gtag-script-analytics">
                                {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'AW-10997782367');
        `}
                              </Script>
                              <Script
                                async
                                src="https://www.googletagmanager.com/gtag/js?id=UA-214155547-1"
                              />
                              <Script strategy="afterInteractive" id="gtm-manager">
                                {`
    window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-XQM3T5M71E');
    `}
                              </Script>
                              <Script strategy="lazyOnload" id="gtag-manager">
                                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-TJHFQ7S');
  `}
                              </Script>
                              {/* <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=GTM-TJHFQ7S`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript> */}
                            </>
                            );
};

                            export default Meta;
